import { Disclosure as Disc } from "@headlessui/react";
import React from "react";
import IconMinus from "./icons/minus";
import IconPlus from "./icons/plus";

export interface DisclosureProps {
  title: string;
  children: React.ReactNode;
}

const Disclosure: React.FC<DisclosureProps> = ({ title, children }) => {
  return (
    <Disc>
      {({ open }) => (
        <>
          <Disc.Button className="flex flex-row items-center w-full gap-3 py-3">
            {open ? (
              <IconMinus className="flex-shrink-0 w-5 h-5" />
            ) : (
              <IconPlus className="flex-shrink-0 w-5 h-5" />
            )}
            <h2 className="text-lg text-left">{title}</h2>
          </Disc.Button>
          <Disc.Panel className="pl-1 text-gray-500">
            <div className="w-full pt-2 pb-4 prose max-w-none prose-slate prose-headings:text-slate-700">
              {children}
            </div>
          </Disc.Panel>
        </>
      )}
    </Disc>
  );
};

export default Disclosure;
