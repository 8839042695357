/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SimplePageTemplate from "../templates/simple-page";
import Disclosure from "../components/disclosure";
const MDXLayout = SimplePageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("h1", {
    className: "text-4xl"
  }, "Links"), "\n", React.createElement("hr"), "\n", React.createElement(Disclosure, {
    title: "Ärztekammern"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bundesaerztekammer.de/"
  }, "Bundesärztekammer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.aekno.de/"
  }, "Landesärztekammer NRW")), "\n")), "\n", React.createElement("hr"), "\n", React.createElement(Disclosure, {
    title: "Psychotherapeutenkammern"
  }, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.bptk.de/"
  }, "Bundespsychotherapeutenkammer")), React.createElement(_components.h4, null, "Psychotherapeutenkammern der Länder"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.lpk-bw.de/"
  }, "Landespsychotherapeutenkammer Baden-Württemberg")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.psychotherapeutenkammer-bayern.de/"
  }, "Bayerische Landeskammer für Psychologische Psychotherapeuten und und Kinder- und Jugendlichenpsychotherapeuten")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.psychotherapeutenkammer-berlin.de/"
  }, "Kammer für Psychologische Psychotherapeuten und Kinder- und Jugendlichenpsychotherapeuten")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.lpk-hb.de/"
  }, "Psychotherapeutenkammer Bremen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ptk-hamburg.de/"
  }, "Hamburgische Psychotherapeutenkammer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.psychotherapeutenkammer-hessen.de/"
  }, "Landeskammer für Psychologische Psychotherapeuten und Kinder- und Jugendlichenpsychotherapeuten Hessen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.pk-nds.de/"
  }, "Psychotherapeutenkammer Niedersachsen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ptk-nrw.de/"
  }, "Psychotherapeutenkammer NRW")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.opk-info.de/"
  }, "Ostdeutsche Psychotherapeutenkammer (OPK)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.lpk-rlp.de/"
  }, "Landespsychotherapeutenkammer Rheinland -Pfalz")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ptk-saar.de/"
  }, "Psychotherapeutenkammer des Saarlandes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.pksh.de/"
  }, "Psychotherapeutenkammer Schleswig-Holstein")), "\n")), "\n", React.createElement("hr"), "\n", React.createElement(Disclosure, {
    title: "Berufs- und Fachverbände und Fachgesellschaften"
  }, React.createElement(_components.h4, null, "Berufsverbände (Psychologische Psychotherapeuten und Kinder- und Jugendlichenpsychotherapeuten)"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bkj-ev.de/"
  }, "(BKJ) Berufsverband der Kinder- und Jugendlichenpsychotherapeutinnen und Kinder- und Jgendlichenpsychotherapeuten e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dptv.de/"
  }, "(DPtV) Deutsche PsychotherapeutenVereinigung e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.vpp.org/"
  }, "(VPP im BDP) Verband Psychologischer Psychotherapeutinnen und Psychotherapeuten im BDP e.V.")), "\n"), React.createElement(_components.h4, null, "Gemischte Berufsverbände (Ärztliche und Psychologische Psychotherapeuten)"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bvvp.de/"
  }, "(bvvp) Bundesverband der Vertragspsychotherapeuten e.V.")), "\n"), React.createElement(_components.h4, null, "Fachverbände, Fachgesellschaften"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.avm-d.de/"
  }, "(AVM) Arbeitsgemeinschaft für Verhaltensmodifikation e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgpt.de/"
  }, "(BPP) Berufsverband der Psychologischen Psychoanalytikerinnen und Psychoanalytiker in der DGPT")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bvkp.de/"
  }, "(BVKP) Bundesverband der Klinikpsychotherapeuten")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dft-online.de/"
  }, "(DFT) Deutsche Fachgesellschaft für tiefenpsychologisch fundierte Psychotherapie e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.verhaltenstherapie.de/"
  }, "(DVT) Deutscher Fachverband für Verhaltenstherapie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.cgjung.de/"
  }, "(DGAP) Deutsche Gesellschaft für Analytische Psychologie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgip.de/"
  }, "(DGIP) Deutsche Gesellschaft für Individualpsychologie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.koerperpsychotherapie-dgk.de/"
  }, "(DGK) Deutsche Gesellschaft für Körperpsychotherapie e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgps.de/"
  }, "(DGPs) Deutsche Gesellschaft für Psychologie, Fachgruppe Klinische und Psychotherapie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgppn.de/"
  }, "(DGPPN) Deutsche Gesellschaft für Psychiatrie, Psychotherapie und Nervenheilkunde")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgpsf.de/"
  }, "(DGPSF) Deutsche Gesellschaft für psychologische Schmerztherapie und -forschung")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgfs.info/"
  }, "(DGfS) Deutsche Gesellschaft für Sexualforschung e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgsf.org/"
  }, "(DGSF) Deutsche Gesellschaft für Systemische Therapie und Familientherapie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgvt.de/"
  }, "(DGVT) Deutsche Gesellschaft für Verhaltenstherapie e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dpgg.de/"
  }, "(DPGG) Deutsche Psychologische Gesellschaft für Gesprächspsychotherapie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dpv-psa.de/"
  }, "(DPV) Deutsche Psychoanalytische Vereinigung")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.balintgesellschaft.de/"
  }, "Deutsche Balintgesellschaft")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.dgbs.de/"
  }, "Deutsche Gesellschaft für bipolare Störung")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.zwaenge.de/index.htm"
  }, "Deutsche Gesellschaft für Zwangserkrankungen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.emdria.de/"
  }, "EMDRIA Deutschland e.V. Fachverband für Anwender der psychotherapeutischen Methode Eye Movement Desensitization and Reprocessing (EMDR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kompetenznetz-schizophrenie.de/rdkns/index.htm"
  }, "Kompetenznetzwerk Schizophrenie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.gnp.de/"
  }, "(GNP) Gesellschaft für Neuropsychologie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.gwg-ev.org/"
  }, "Gesellschaft für personenzentrierte Psychotherapie und Beratung e. V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.meg-hypnose.de/"
  }, "(M.E.G.) Milton Erickson Gesellschaft für Klinische Hypnose")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ngfp.de/"
  }, "(NGfP) Neue Gesellschaft für Psychologie")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.systemische-gesellschaft.de/"
  }, "Systemische Gesellschaft (SG) Deutscher Verband für systemische Forschung, Therapie, Supervision und Beratung e.V.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.vakjp.de/"
  }, "(VAKJP) Vereinigung Analytischer Kinder- und Jugendlichen-Psychotherapeuten")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.vivt.de/"
  }, "(VIVT) Verband für integrative Verhaltenstherapie")), "\n")), "\n", React.createElement("hr"), "\n", React.createElement(Disclosure, {
    title: "Rechtsgrundlagen für den Bereich der Psychotherapie"
  }, React.createElement(_components.h4, null, "Hier finden Sie die wesentlichen relevanten Gesetze, Richtlinien und Verordnungen"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.e-bis.de/goae/defaultFrame.htm"
  }, "GOÄ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.g-ba.de/informationen/richtlinien/18/"
  }, "GB-A Richtlinie Qualitätsmanagement")), "\n")), "\n", React.createElement("hr"), "\n", React.createElement(Disclosure, {
    title: "Links für die Therapeutensuche"
  }, React.createElement(_components.h4, null, "Links für die Therapeutensuche"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.kvno.de/20patienten/10arztsuche/index.html"
  }, "KV-Nordrhein")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.psychotherapiesuche.de/"
  }, "Suche beim Berufsverband deutscher Psychologen (BDP)")), "\n"), React.createElement(_components.p, null, "Zudem finden sich auf den Seiten aller Psychotherapeutenkammern und aller Ärztekammern (s.o.) Listen und Links der in den Regionen ansässigen Psychotherapeuten und der Kinder- und Jungendlichenpsychotherapeuten.")), "\n", React.createElement("hr"), "\n", React.createElement(Disclosure, {
    title: "Selbsthilfe und Beratung"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.adhs.ch/"
  }, "ADHS bei Kindern und Erwachsenen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.aidshilfe.de/"
  }, "AIDS-Hilfe")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.panik-attacken.de/"
  }, "Angst- Hilfe EV")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.angstportal.de/"
  }, "Angst Portal (Internetplattform für Angsterkrankungen)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.anonyme-alkoholiker.de/"
  }, "Anonyme Alkoholiker")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.ab-server.de/"
  }, "Beratungs- und Informationsserver zu Essstörungen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.aids-stiftung.de/"
  }, "Deutsche AIDS-Stiftung")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.depression-duesseldorf.de/"
  }, "Düsseldorfer Bündnis gegen Depression")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.nakos.de/site"
  }, "Nationale Kontakt- und Informationsstelle zur Anregung und Unterstützung von Selbsthilfegruppen")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.profamilia.de/"
  }, "Pro familia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.selbsthilfenetz.de/"
  }, "Selbsthilfenetzwerk")), "\n")));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
